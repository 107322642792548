import glsl from 'babel-plugin-glsl/macro'

const fragmentShader = 
    glsl`
    float circleSDF(vec2 vUv){
        return length(vUv - .5) * 2.;
    }
    
    float circleSDF2(vec2 vUv){
        return length(vUv - .5) * 2.;
    }
    
    float circleSDF3(vec2 vUv){
        return length(vUv - .5) * 2.;
    }
    
    float circleSDF4(vec2 vUv){
        return length(vUv - .5) * 2.;
    }
    
    float circleSDF5(vec2 vUv){
        return length(vUv - .5) * 2.;
    }
    
    float circleSDF6(vec2 vUv){
        return length(vUv - .5) * 2.;
    }
    
    float stroke(float x, float s, float w){
        float d = step(s, x+w*.5) -
                  step(s, x-w*.5);
        return clamp(d, 0., 1.);
    }
    
    
    
    void main(){
        vec3 color = vec3(0.);
    
        color.rg += stroke(circleSDF(vec2(vUv.x + .125, vUv.y - 0.08)), 0.2, 0.03);
        color.b += stroke(circleSDF2(vec2(vUv.x + .3 - .05, vUv.y - .2)), 0.2, 0.03);
        color += stroke(circleSDF(vec2(vUv.x , vUv.y - .2)), 0.2, 0.03);
        color.g += stroke(circleSDF(vec2(vUv.x - .124, vUv.y - .08)), 0.2, 0.03);
        color.r += stroke(circleSDF(vec2(vUv.x  - .4 + .15, vUv.y - .2)), 0.2, 0.03);;
        //color += stroke(circleSDF6(vec2(vUv)), 0.5, 0.02);
    
        gl_FragColor = vec4(color, 1.);
    }
    `

    const vertexShader = glsl`
    varying vec2 vUv;
    
    void main()
    {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
    }`
    
    import { Vector2, ShaderMaterial } from 'three'
    import { useRef } from 'react'
    import { useFrame } from '@react-three/fiber'
    import numbers from '../numLabels/numbers.js'
    import preload from '../preload/preload.js'
    import usefulFunctions from '../usefulFunctions/usefulFunctions.js'
    
    
    
    const material = new ShaderMaterial({
        vertexShader: vertexShader,
    
        //use for shaders <425
        //fragmentShader: fragment
    
        //use for shader >= 425
        //clean up the fragment shader
        //imports from preload, numbers and useful functions
        fragmentShader: preload + usefulFunctions + numbers + fragmentShader,
        uniforms: {
            u_time: { type: "f", value: 1.0 },
            u_resolution: { type: "v2", value: new Vector2() },
            u_mouse: { type: "v2", value: new Vector2() },
            // u_texture: {type: "t", value: useLoader(TextureLoader, img) }
        }
    })
    
    // console.log(material.fragmentShader)
    
    export default function Shader017()
    {
        const meshRef = useRef()
        // const tex = useLoader(TextureLoader, img)
        // console.log(tex)
        
        useFrame(({clock}) => {
            meshRef.current.material.uniforms.u_time.value = clock.elapsedTime
        })
    
        return (
            <>
                <mesh ref={meshRef} material={material} >
                    <boxGeometry args={[2, 2, 0.1]} />
                </mesh>
            </>
        )
    }